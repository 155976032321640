import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./TableSetupSettings.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import SettingSelectField from "../../components/SettingSelectField";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";

let table_type_list = [
  { label: "Basic Table and Floor Layout", value: "ordered" },
  { label: "Custom Table and Floor Layout", value: "layout" },
];

const TableSetupSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );

  const [loading, setLoading] = useState(false);
  const [tableType, setTableType] = useState("");
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let data = restaurantDetail;
    let data1 = {
      tableType: tableType ? tableType : null,
    };

    if (data?.tableType !== data1.tableType) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [tableType, restaurantDetail]);

  useEffect(() => {
    getRestaurantData();
  }, []);

  const getRestaurantData = () => {
    setLoading(true);
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        let resData = response.data;
        setTableType(resData?.tableType ? resData?.tableType : "");
        setRestaurantDetail(resData);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onSave = () => {
    if (tableType == "") {
      setMsgAlert({
        open: true,
        message: "Table Type is Required",
        msgType: "error",
      });
      return;
    }

    let data = {
      tableType: tableType ? tableType : null,
    };

    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, restaurantId).then((response) => {
      if (response.success) {
        setRestaurantDetail({
          ...restaurantDetail,
          ...data,
        });
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {" "}
      {loading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={"Table Setup Settings"}
              onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
            />
          </Grid>
          <Grid item xs={12} className={styles.mainView} px={3} pt={2}>
            <Grid item xs={12} md={5.8}>
              <Box mb={1}>
                <Typography className={styles.textInputLabel}>
                  Table Type
                </Typography>
              </Box>
              <Box>
                <SettingSelectField
                  page={"settings"}
                  value={tableType}
                  setValue={setTableType}
                  border={"1px solid #AAA"}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  menuList={table_type_list}
                />
              </Box>
              <Grid container item xs={12} mt={2}>
                <Grid item xs={6}>
                  <Box className={styles.btnMainView}>
                    <Button
                      type="button"
                      disabled={saveBtnDisabled}
                      className={styles.saveBtn}
                      sx={{
                        opacity: saveBtnDisabled ? 0.2 : 1,
                        "&:hover": {
                          backgroundColor: "#000 !important",
                        },
                      }}
                      onClick={saveBtnDisabled ? null : onSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default TableSetupSettings;
