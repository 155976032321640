import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import moment from "moment";

import userSquare from "./../assets/images/user_square.svg";

import "./../assets/css/custom.css";

import { THEME_COLOR2 } from "../constants";

const UserList = (props) => {
  const { userId, data } = props;

  const onChangePswd = () => {
    props.onChangePswd(data);
  };

  const onDeleteUser = () => {
    props.onDelete(data);
  };

  return (
    <Card sx={styles.mainGrid}>
      <Grid container>
        <Grid container item xs={12} md={1} alignItems="center">
          <Grid
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              height: { xs: 80, md: 75 },
              width: { xs: "100%", md: "85%" },
            }}
          >
            <img src={userSquare} style={styles.userImage} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={8}
          direction="row"
          sx={{ marginTop: { xs: 1, md: 0 } }}
        >
          <Grid container item xs={12} md={4} direction="column">
            <Typography sx={styles.nameText}>{data.name}</Typography>
            <Typography sx={styles.descText}>
              Added Date: {moment(data?.createdAt).format("DD MMM Y")}
            </Typography>
            <Typography sx={styles.mobileText}>{data.email}</Typography>
            {/*<Typography sx={styles.detailText}>Restaurant Details</Typography>*/}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            type="button"
            sx={{
              backgroundColor: "#a9c3dc",
              color: "#000",
              width: 120,
              height: 30,
              fontFamily: "GilroySemiBold",
              fontSize: 10,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "#a9c3dc !important",
              },
              mx: 1,
            }}
            onClick={onChangePswd}
          >
            Change Password
          </Button>
          {userId && userId !== data?._id ? (
            <Button
              type="button"
              sx={{
                backgroundColor: "#f14136",
                color: "#FFF",
                width: 120,
                height: 30,
                fontFamily: "GilroySemiBold",
                fontSize: 10,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#f14136 !important",
                },
                mx: 1,
              }}
              onClick={onDeleteUser}
            >
              Delete
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
};

export default UserList;

const styles = {
  mainGrid: {
    cursor: "pointer",
    borderRadius: 3,
    width: "100%",
    marginBottom: 2,
    padding: 1.3,
  },
  userImage: {
    width: "100%",
    height: "100%",
  },
  nameText: {
    color: THEME_COLOR2,
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  descText: {
    color: "#888",
    fontFamily: "GilroyMedium",
    fontSize: 10,
    lineHeight: 1,
    mt: 1,
  },
  mobileText: {
    fontFamily: "GilroyBold",
    fontSize: 11,
    lineHeight: 2,
  },
  placeText: {
    color: "#888",
    fontFamily: "GilroyMedium",
    fontSize: 11,
  },
  dotBox: {
    backgroundColor: "#000",
    border: "1px solid #888",
    borderRadius: 50,
    height: 3,
    width: 3,
    ml: 0.5,
    mr: 0.5,
  },
  distanceText: {
    color: "#888",
    fontFamily: "GilroySemiBold",
    fontSize: 11,
  },
  detailText: {
    color: "#888",
    fontFamily: "GilroyMedium",
    fontSize: 11.5,
    textDecoration: "underline",
  },
};
