import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

import * as Api from "../../api";

import Container from "../../components/Container";
import RestaurantList from "../../components/RestaurantList";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import SettingSelectField from "../../components/SettingSelectField";

import { COUNTRY_LIST } from "../../constants";

import "../../assets/css/custom.css";

const pageSize = 20;

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const OPTION_LIST = [
  { _id: "all", name: "All Options" },
  { _id: "dine_in", name: "Dine in" },
  { _id: "take_away", name: "Take away" },
  { _id: "both", name: "Both" },
];

const Restaurants = () => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [restaurantAllList, setRestaurantAllList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("all");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("all");
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");
  const [pageNum, setPageNum] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const navigate = useNavigate();

  useEffect(() => {
    updateRestList();
  }, [
    searchText,
    selectedPlan,
    selectedCategory,
    selectedCountry,
    selectedCity,
    selectedOption,
  ]);

  useEffect(() => {
    getRestaurantList();
  }, []);

  useEffect(() => {
    getRestaurantCategoryList();
  }, []);

  const getRestaurantList = () => {
    setIsLoading(true);
    Api.restaurantList("/all").then((response) => {
      if (response.success) {
        setTotalPages(Math.ceil(response.data.count / pageSize));
        const plnList = [{ _id: "all", name: "All Plans" }];
        let ctyList = [];
        const list = response.data.rows
          .sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))
          .map((rst) => {
            if (rst?.accountDetails?._idSubscriptions) {
              const { _idSubscriptions = "", subscriptionsName = "" } =
                rst?.accountDetails || {};
              if (
                plnList.findIndex((y) => y?._id === _idSubscriptions) < 0 &&
                _idSubscriptions !== ""
              ) {
                plnList.push({
                  _id: _idSubscriptions,
                  name: `${subscriptionsName} Plan`,
                });
              }
            }
            if (rst?.address?.city) {
              const { city = "" } = rst?.address || {};
              if (
                ctyList.findIndex((y) => y?._id === city?.toLowerCase()) < 0 &&
                city !== ""
              ) {
                ctyList.push({
                  _id: city?.toLowerCase(),
                  name: capitalizeFirstLetter(city),
                });
              }
            }
            return { ...rst, selected: false };
          });
        setPlanList(plnList);
        ctyList = ctyList.sort((a, b) => a._id.localeCompare(b._id));
        setCityList([{ _id: "all", name: "All Cities" }, ...ctyList]);
        setRestaurantList(list.filter((_, i) => i < pageSize));
        setRestaurantAllList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const getRestaurantCategoryList = async () => {
    const response = await Api.getRestaurantCategoryList("all");
    if (response?.success) {
      setCategoryList([
        { _id: "all", name: "All Categories" },
        ...response?.data?.rows,
      ]);
    } else {
      setMsgAlert({ open: true, message: response?.msg, msgType: "error" });
    }
  };

  const onSelectPage = (value) => {
    setPageNum(value);
    setRestaurantList(
      filterRestList().filter(
        (_, i) => i >= (value - 1) * pageSize && i < value * pageSize
      )
    );
  };

  const updateRestList = () => {
    let list = filterRestList();
    setTotalPages(Math.ceil(list.length / pageSize));
    list = list.filter((_, i) => i < pageSize);
    setPageNum(1);
    setRestaurantList(list);
  };

  const filterRestList = () => {
    return restaurantAllList.filter(
      (x) =>
        x.name.toLowerCase().includes(searchText.toLowerCase()) &&
        (x?._idCategory === selectedCategory || selectedCategory === "all") &&
        (x?.address?.countryCode === selectedCountry ||
          selectedCountry === "all") &&
        (x?.address?.city?.toLowerCase() === selectedCity ||
          selectedCity === "all") &&
        ((x?.options?.hasDineIn === true && selectedOption === "dine_in") ||
          (x?.options?.hasTakeAway === true &&
            selectedOption === "take_away") ||
          (x?.options?.hasDineIn === true &&
            x?.options?.hasTakeAway === true &&
            selectedOption === "both") ||
          selectedOption === "all") &&
        (x?.accountDetails?._idSubscriptions === selectedPlan ||
          selectedPlan === "all")
    );
  };

  const onSearchRestaurant = (val) => {
    setSearchText(val);
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onSelectRestaurant = (data) => {
    let restaurant = [...restaurantList];
    restaurant.map((item, index) => {
      item["selected"] = false;
    });
    let restaurantIndex = restaurant.findIndex((item) => item._id === data._id);
    if (restaurantIndex >= 0) {
      restaurant[restaurantIndex]["selected"] =
        !restaurant[restaurantIndex]["selected"];
      setRestaurantList(restaurant);
    }
    // navigate('/restaurant/' + data._id);
    window.open(window.location.origin + "/restaurant/" + data._id, "_blank");
  };

  const handlerOnSelect = (type, val) => {
    if (type === "plan") {
      setSelectedPlan(val);
    } else if (type === "category") {
      setSelectedCategory(val);
    } else if (type === "country") {
      setSelectedCountry(val);
    } else if (type === "city") {
      setSelectedCity(val);
    } else if (type === "option") {
      setSelectedOption(val);
    }
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={searchText}
      onSearch={onSearchRestaurant}
      searchLabel="Restaurants"
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Box sx={styles.titleMainBox} mb={1}>
            <Typography variant={"h6"} sx={styles.restaurantText}>
              Restaurants
            </Typography>

            <Box
              sx={styles.addRestaurantBox}
              onClick={() => navigate("/add_restaurant")}
            >
              <Typography sx={styles.plusText}>+</Typography>
              <Typography sx={styles.addRestaurantText} ml={0.5}>
                Add New Restaurant
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap" }}
          >
            <Box mt={1} mr={1}>
              <SettingSelectField
                page={"restaurants"}
                value={selectedOption}
                setValue={(val) => handlerOnSelect("option", val)}
                border={"1px solid #AAA"}
                textInputViewStyle={styles.selectBox}
                placeholder={"Options"}
                required={true}
                variant={"filled"}
                menuList={OPTION_LIST}
                labelField={"name"}
                valueField={"_id"}
              />
            </Box>
            <Box mt={1} mr={1}>
              <SettingSelectField
                page={"restaurants"}
                value={selectedCategory}
                setValue={(val) => handlerOnSelect("category", val)}
                border={"1px solid #AAA"}
                textInputViewStyle={styles.selectBox}
                placeholder={"Categories"}
                required={true}
                variant={"filled"}
                menuList={categoryList}
                labelField={"name"}
                valueField={"_id"}
              />
            </Box>
            <Box mt={1} mr={1}>
              <SettingSelectField
                page={"restaurants"}
                value={selectedCountry}
                setValue={(val) => handlerOnSelect("country", val)}
                border={"1px solid #AAA"}
                textInputViewStyle={styles.selectBox}
                placeholder={"Countries"}
                required={true}
                variant={"filled"}
                menuList={[
                  { _id: "all", name: "All Countries" },
                  ...COUNTRY_LIST.map((x) => {
                    return { _id: x, name: x };
                  }),
                ]}
                labelField={"name"}
                valueField={"_id"}
              />
            </Box>
            <Box mt={1} mr={1}>
              <SettingSelectField
                page={"restaurants"}
                value={selectedCity}
                setValue={(val) => handlerOnSelect("city", val)}
                border={"1px solid #AAA"}
                textInputViewStyle={styles.selectBox}
                placeholder={"Cities"}
                required={true}
                variant={"filled"}
                menuList={cityList}
                labelField={"name"}
                valueField={"_id"}
              />
            </Box>
            <Box mt={1}>
              <SettingSelectField
                page={"restaurants"}
                value={selectedPlan}
                setValue={(val) => handlerOnSelect("plan", val)}
                border={"1px solid #AAA"}
                textInputViewStyle={styles.selectBox}
                placeholder={"Plans"}
                required={true}
                variant={"filled"}
                menuList={planList}
                labelField={"name"}
                valueField={"_id"}
              />
            </Box>
          </Box>
          <Grid style={{ minHeight: window.innerHeight - 220 }}>
            <Grid container direction="row" mt={2}>
              {restaurantList.map((item, index) => {
                return (
                  <RestaurantList
                    key={index}
                    data={item}
                    onSelectRestaurant={onSelectRestaurant}
                  />
                );
              })}
            </Grid>
          </Grid>
          {totalPages > 0 ? (
            <Page
              totalPages={totalPages}
              pageNum={pageNum}
              onSelectPage={onSelectPage}
            />
          ) : null}
        </>
      )}
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Container>
  );
};

export default Restaurants;

const styles = {
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectBox: {
    width: 170,
    height: 40,
    borderRadius: 2,
  },
  restaurantText: {
    fontFamily: "GilroyBold",
  },
  addRestaurantBox: {
    backgroundColor: "#FF0000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "10px",
    px: 2,
  },
  plusText: {
    fontFamily: "GilroySemiBold",
    fontSize: 30,
    color: "#FFF",
  },
  addRestaurantText: {
    fontFamily: "GilroySemiBold",
    fontSize: 14,
    color: "#FFF",
  },
};
