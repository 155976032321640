import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./CustomRestaurantInfo.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import UploadLogo from "../../components/UploadLogo";
import SettingTextField from "../../components/SettingTextField";
import SettingFileInput from "../../components/SettingFileInput";
import SwitchInput from "../../components/SwitchInput";
import SocialMedia from "../../components/SocialMedia";
import SettingSelectField from "../../components/SettingSelectField";
import RestaurantHeader from "../../components/RestaurantHeader";
import PortalSettingsColorInput from "../../components/PortalSettingsColorInput";

import {
  APP_TYPE_BOTH,
  APP_TYPE_FOODO,
  APP_TYPE_GOPOSH,
} from "../../constants";

import { ReactComponent as InfoIcon } from "../../assets/images/rest_info.svg";

let RESTLIST = ["6509e4d7d1197a7aa90df7df"]; //rokka

const InfoList = {
  printLogo: "Upload the logo that needs to be printed in receipts",
  mainHeaderBg:
    "Header background image that needs to be shown on ar 3d page top section",
  headerBg: "",
  arModelBg: "Background image shown at the 3d model page",
  bottomMenuIcon:
    "Icon image shown for the menu button in 3d page bottom centre section",
  menuSectionBg: "Background image for bottom menu section in ar 3d page",
  prevIcon:
    "Icon image shown for the previous button in 3d page bottom menu section",
  nextIcon:
    "Icon image shown for the next button in 3d page bottom menu section",
  onlineOrder: "Toggle option for user to place order online",
  tableOrder: "Toggle option for user to place table order",
  tablePayment:
    "Toggle option for user to make payment for table and online order",
  wifiAvailable: "Toggle option to show wifi icon in 3d menu page for user",
  menuItemFontBg:
    "Background color for menu name & price section in the ar page",
  menuItemFontColor:
    "Font color for menu name & price in the top section in the ar page",
  modalBgColor: "Background color for user form modal in the ar page",
  modalFontColor: "Font color for texts in user form modal in the ar page",
  userFormDataBg:
    "Background color for input section in user modal form in the ar page",
  userFormDataLabelColor:
    "Font color for input label in user form modal in the ar page",
  userFormDataFontColor:
    "Font color for inputs in user form modal in the ar page",
  buttonBg: "Background color for button in user form modal in the ar page",
  buttonTextBg: "Font color for button text in user form modal in the ar page",
};

const MenuListThemeColor = [
  {
    label: "Bg Color",
    name: "menuListingBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for menu list page",
  },
  {
    label: "Font Color",
    name: "menuListingFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for category name, menu name, description, price in menu list page",
  },
  {
    label: "Category Font Color",
    name: "menuListingCategoryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for categories shown in the top section of the menu list page",
  },
  {
    label: "Category Bg Color",
    name: "menuListingCategoryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for categories shown in the top section of the menu list page",
  },
  {
    label: "Selected Category Font Color",
    name: "menuListingSelectedCategoryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for selected category shown in the top section of the menu list page",
  },
  {
    label: "Selected Category Bg Color",
    name: "menuListingSelectedCategoryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for selected category shown in the top section of the menu list page",
  },
  {
    label: "Dish Notes Bg Color",
    name: "menuListingDishNotesBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for dish notes in menu list page",
  },
  {
    label: "Dish Notes Font Color",
    name: "menuListingDishNotesFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for dish notes in menu list page",
  },
  {
    label: "Add Btn Bg Color",
    name: "menuListingAddBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for menu add button shown in the bottom of menu image in the menu list page",
  },
  {
    label: "Add Btn Font Color",
    name: "menuListingAddBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu add button shown in the bottom of menu image in the menu list page",
  },
  {
    label: "Item Img Bg Color",
    name: "menuListingItemImgBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for menu image in the menu list page",
  },
  {
    label: "Ar Btn Bg Color",
    name: "menuListingArBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for AR icon shown in the top right of menu image in the menu list page",
  },
  {
    label: "Ar Btn Icon Color",
    name: "menuListingArBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for AR icon shown in the top right of menu image in the menu list page",
  },
  {
    label: "Menu Type Filter Bg Color",
    name: "menuListingMenuTypeFilterBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for menu type filter shown in the top left of the menu list page",
  },
  {
    label: "Menu Type Filter Font Color",
    name: "menuListingMenuTypeFilterFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu type filter shown in the top left of the menu list page",
  },
  {
    label: "Back Btn Color",
    name: "menuListingBackBtnColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for back icon shown in the top left of the menu list page",
  },
  {
    label: "Search Input Bg Color",
    name: "menuListingSearchInputBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for search input shown in the top of the menu list page",
  },
  {
    label: "Search Input Font Color",
    name: "menuListingSearchInputFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for search input shown in the top of the menu list page",
  },
  {
    label: "Search Input Icon Color",
    name: "menuListingSearchInputIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for search icon shown in the top of the menu list page",
  },
  {
    label: "Order History Icon Color",
    name: "menuListingOrderHistoryIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for order history icon shown in the right top of the menu list page",
  },
  {
    label: "Top Header Shadow",
    name: "menuListingTopHeaderShadow",
    value: true,
    defaultValue: true,
    type: "switch",
    info: "Toggle option to show top header shadow in menu listing page",
  },
];

const MenuDetailPopThemeColor = [
  {
    label: "Item Font Color",
    name: "menuPopupItemFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu name & price in the menu detail popup",
  },
  {
    label: "Item Price Font Size (Default 18px)",
    name: "menuPopupItemPriceFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Item Bg Color",
    name: "menuPopupItemBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for top card section in the menu detail popup",
  },
  {
    label: "Modifier Bg Color",
    name: "menuPopupModifierBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for modifier list section in the menu detail popup",
  },
  {
    label: "Modifier Font Color",
    name: "menuPopupModifierFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for modifier list section in the menu detail popup",
  },
  {
    label: "Modifier Price Font Size (Default 14px)",
    name: "menuPopupModifierPriceFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Add To Cart Btn Bg Color",
    name: "menuPopupAddToCartBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add btn in the bottom section of the menu detail popup & Background color for view cart btn in the bottom section of the menu list page",
  },
  {
    label: "Add To Cart Btn Font Color",
    name: "menuPopupAddToCartBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for add btn in the bottom section of the menu detail popup & Font color for view cart btn in the bottom section of the menu list page",
  },
  {
    label: "Bg Color",
    name: "menuPopupBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for menu detail popup",
  },
  {
    label: "Description Font Color",
    name: "menuPopupItemDescFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu description in the menu detail popup",
  },
  {
    label: "Add Note Bg Color",
    name: "menuPopupAddNoteBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add note section in the menu detail popup",
  },
  {
    label: "Add Note Font Color",
    name: "menuPopupAddNoteFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for add note section in the menu detail popup",
  },
  {
    label: "Qty Btn Bg Color",
    name: "menuPopupQtyBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for quantity add btn in the bottom section of the menu detail popup",
  },
  {
    label: "Qty Btn Font Color",
    name: "menuPopupQtyBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for quantity add btn in the bottom section of the menu detail popup",
  },
];

const CartThemeColor = [
  {
    label: "Bg Color",
    name: "cartBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for cart page",
  },
  {
    label: "Card Header Font Size (Default 15px)",
    name: "cartCardHeaderFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Card Item Name Font Size (Default 14px)",
    name: "cartCardItemNameFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Card Item Price Font Size (Default 14px)",
    name: "cartCardItemPriceFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Card Bg Color",
    name: "cartCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the cart page",
  },
  {
    label: "Card Header Font Color",
    name: "cartCardHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for text in the header section of the card in the cart page",
  },
  {
    label: "Card Item Name Font Color",
    name: "cartCardItemNameFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu name in the card in the cart page",
  },
  {
    label: " Card Item Price Font Color",
    name: "cartCardItemPriceFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for menu price in the card in the cart page",
  },
  {
    label: "Card Modifier Font Color",
    name: "cartCardModifierFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for modifiers in the card in the cart page",
  },
  {
    label: "Card Total Price Font Color",
    name: "cartCardTotalPriceFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for total price in the bottom right of card in the cart page",
  },
  {
    label: "Card Total Qty Font Color",
    name: "cartCardTotalQtyFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for total quantity in the bottom left of card in the cart page",
  },
  {
    label: "Card Qty Bg Color",
    name: "cartCardQtyBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for quantity add btn in the card in the cart page",
  },
  {
    label: "Card Qty Font Color",
    name: "cartCardQtyFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for quantity add btn in the card in the cart page",
  },
  {
    label: "Card Delete Icon Color",
    name: "cartCardDeleteIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for delete icon in the card in the cart page",
  },
  {
    label: "Card Status Bg Color",
    name: "cartCardStatusBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for status in the top right section of the card in the cart page",
  },
  {
    label: "Card Status Font Color",
    name: "cartCardStatusFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for status in the top right section of the card in the cart page",
  },
  {
    label: "View Order Btn Bg Color",
    name: "cartViewOrderBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for view order button in the bottom of the cart page",
  },
  {
    label: "View Order Btn Border Color",
    name: "cartViewOrderBtnBorderColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Border color for view order button in the bottom of the cart page",
  },
  {
    label: "View Order Btn Font Color",
    name: "cartViewOrderBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for view order button in the bottom of the cart page",
  },
  {
    label: "Back Btn Bg Color",
    name: "cartBackBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for back button in the bottom of the cart page",
  },
  {
    label: "Back Btn Border Color",
    name: "cartBackBtnBorderColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Border color for back button in the bottom of the cart page",
  },
  {
    label: "BackBtn Font Color",
    name: "cartBackBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for back button in the bottom of the cart page",
  },
  {
    label: "Checkout Btn Bg Color",
    name: "cartCheckoutBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for checkout button in the bottom of the cart page",
  },
  {
    label: "Checkout Btn Border Color",
    name: "cartCheckoutBtnBorderColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Border color for checkout button in the bottom of the cart page",
  },
  {
    label: "Checkout Btn Font Color",
    name: "cartCheckoutBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for checkout button in the bottom of the cart page",
  },
];

const CheckoutThemeColor = [
  {
    label: "Bg Color",
    name: "checkoutBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for checkout page",
  },
  {
    label: "Cards Bg Color",
    name: "checkoutCardsBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the checkout page",
  },
  {
    label: "Cards Secondary Bg Color",
    name: "checkoutCardsSecondaryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for address section in the shipping card in the checkout page",
  },
  {
    label: "Cards Font Color",
    name: "checkoutCardsFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for card sections in the checkout page",
  },
  {
    label: "Font Color",
    name: "checkoutFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for Tips & sub text in the checkout page",
  },
  {
    label: "Billing Price Font Size (Default 16px)",
    name: "checkoutBillingPriceFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Billing Total Price Font Size (Default 19px)",
    name: "checkoutBillingTotalPriceFontSize",
    value: "",
    defaultValue: null,
    type: "fontsize",
  },
  {
    label: "Payment Btn Bg Color",
    name: "checkoutPaymentBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for pay button in the bottom of the checkout page",
  },
  {
    label: "Payment Btn Font Color",
    name: "checkoutPaymentBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for pay button in the bottom of the checkout page",
  },
  {
    label: "Tips Bg Color",
    name: "checkoutTipsBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for tips list in the checkout page",
  },
  {
    label: "Tips Selected Bg Color",
    name: "checkoutTipsSelectedBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for selected tip in the checkout page",
  },
  {
    label: "Tips Font Color",
    name: "checkoutTipsFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for tips list in the checkout page",
  },
  {
    label: "Tips Selected Font Color",
    name: "checkoutTipsSelectedFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for selected tip in the checkout page",
  },
  {
    label: "Tips Pencil Icon Color",
    name: "checkoutTipsPencilIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for tip edit icon in the checkout page",
  },
  {
    label: "Tips Popup Bg Color",
    name: "checkoutTipsPopupBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for tips popup in the checkout page",
  },
  {
    label: "Tips Popup Title Color",
    name: "checkoutTipsPopupTitleColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for title text in the tips popup in the checkout page",
  },
  {
    label: "Tips Popup Font Color",
    name: "checkoutTipsPopupFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for text in the bottom of the tips popup in the checkout page",
  },
  {
    label: "Tips Popup Label Color",
    name: "checkoutTipsPopupLabelColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for input label in the tips popup in the checkout page",
  },
  {
    label: "Tips Popup Add Btn Bg Color",
    name: "checkoutTipsPopupAddBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add button in the tips popup in the checkout page",
  },
  {
    label: "Tips Popup Add Btn Font Color",
    name: "checkoutTipsPopupAddBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for add button in the tips popup in the checkout page",
  },
  {
    label: "Table No Bg Color",
    name: "checkoutTableNoBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for table number in the top card section of the checkout page",
  },
  {
    label: "Table No Font Color",
    name: "checkoutTableNoFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for table number in the top card section of the checkout page",
  },
];

const OrderSuccessThemeColor = [
  {
    label: "Bg Color",
    name: "orderSuccessBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for order success page",
  },
  {
    label: "Header Bg Color",
    name: "orderSuccessHeaderBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header in the order success page",
  },
  {
    label: "Header Font Color",
    name: "orderSuccessHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for header title in the order success page",
  },
  {
    label: "Card Bg Color",
    name: "orderSuccessCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the order success page",
  },
  {
    label: "Card Font Color",
    name: "orderSuccessCardFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for card sections in the order success page",
  },
  {
    label: "Mini Invoice Widget Primary Bg Color",
    name: "orderSuccessMiniInvoiceWidgetPrimaryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for mini invoice widget card in the order success page",
  },
  {
    label: "Mini Invoice Widget Primary Font Color",
    name: "orderSuccessMiniInvoiceWidgetPrimaryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for mini invoice widget card in the order success page",
  },
  {
    label: "Mini Invoice Widget Secondary Bg Color",
    name: "orderSuccessMiniInvoiceWidgetSecondaryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for bottom section of the mini invoice widget card in the order success page",
  },
  {
    label: "Mini Invoice Widget Secondary Font Color",
    name: "orderSuccessMiniInvoiceWidgetSecondaryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for bottom section of the mini invoice widget card in the order success page",
  },
  {
    label: "Back Btn Bg Color",
    name: "orderSuccessBackBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for back button in the bottom of the order success page",
  },
  {
    label: "Back Btn Font Color",
    name: "orderSuccessBackBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for back button in the bottom of the order success page",
  },
];

const OrderHistoryThemeColor = [
  {
    label: "Header Bg Color",
    name: "orderHistoryHeaderBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header in the order history page",
  },
  {
    label: "Header Font Color",
    name: "orderHistoryHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for header title in the order history page",
  },
  {
    label: "Bg Color",
    name: "orderHistoryBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for order history page",
  },
  {
    label: "Search Input Bg Color",
    name: "orderHistorySearchInputBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for search input in the order history page",
  },
  {
    label: "Search Input Label Font Color",
    name: "orderHistorySearchInputLabelFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for search input label in the order history page",
  },
  {
    label: "Search Input Font Color",
    name: "orderHistorySearchInputFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for search input in the order history page",
  },
  {
    label: "Card Bg Color",
    name: "orderHistoryCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the order history page",
  },
  {
    label: "Card Font Color",
    name: "orderHistoryCardFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for card sections in the order history page",
  },
  {
    label: "Card Table No. Bg Color",
    name: "orderHistoryCardTableNoBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for table number in the card in the order history page",
  },
  {
    label: "Card Table No. Font Color",
    name: "orderHistoryCardTableNoFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for table number in the card in the order history page",
  },
];

const OrderDetailThemeColor = [
  {
    label: "Header Bg Color",
    name: "orderDetailHeaderBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header in the order detail page",
  },
  {
    label: "Header Font Color",
    name: "orderDetailHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for header title in the order detail",
  },
  {
    label: "Bg Color",
    name: "orderDetailBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for order detail page",
  },
  {
    label: "Card Bg Color",
    name: "orderDetailCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the order detail page",
  },
  {
    label: "Card Font Color",
    name: "orderDetailCardFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for card sections in the order detail page",
  },
  {
    label: "Card Bill Widget Bg Color",
    name: "orderDetailCardBillWidgetBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for bill widget card section in the order detail page",
  },
  {
    label: "Card Bill Widget Font Color",
    name: "orderDetailCardBillWidgetFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for bill widget card section in the order detail page",
  },
];

const AddAddressThemeColor = [
  {
    label: "Header Bg Color",
    name: "addAddressHeaderBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header in the add address page",
  },
  {
    label: "Header Font Color",
    name: "addAddressHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for header title in the add address page",
  },
  {
    label: "Back Btn Bg Color",
    name: "addAddressBackBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header back button in the add address page",
  },
  {
    label: "Back Btn Icon Color",
    name: "addAddressBackBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for header back icon in the add address page",
  },
  {
    label: "Bg Color",
    name: "addAddressBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add address page",
  },
  {
    label: "Card Bg Color",
    name: "addAddressCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the add address page",
  },
  {
    label: "Card Title Font Color",
    name: "addAddressCardTitleFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for title text in the card in the add address page",
  },
  {
    label: "Card Input Label Font Color",
    name: "addAddressCardInputLabelFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for label text in the card in the add address page",
  },
  {
    label: "Card Input Bg Color",
    name: "addAddressCardInputBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for inputs in the card in the add address page",
  },
  {
    label: "Card Input Font Color",
    name: "addAddressCardInputFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for inputs in the card in the add address page",
  },
  {
    label: "Card Input Icon Color",
    name: "addAddressCardInputIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for icon in the card inputs in the add address page",
  },
  {
    label: "Add Icon Bg Color",
    name: "addAddressAddBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add icon in the address card section in the add address page",
  },
  {
    label: "Add Icon & Font Color",
    name: "addAddressAddBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for add icon & text in the address card section in the add address page",
  },
  {
    label: "Checkbox Btn Icon Color",
    name: "addAddressCheckboxBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for checkbox btn in the add address page",
  },
  {
    label: "Radio Btn Icon Color",
    name: "addAddressRadioBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for radio btn in the add address page",
  },
  {
    label: "Save Btn Bg Color",
    name: "addAddressSaveBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for save button in the add address page",
  },
  {
    label: "Save Btn Font Color",
    name: "addAddressSaveBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for save button in the add address page",
  },
  {
    label: "Cancel Btn Bg Color",
    name: "addAddressCancelBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for cancel button in the add address page",
  },
  {
    label: "Cancel Btn Font Color",
    name: "addAddressCancelBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for cancel button in the add address page",
  },
];

const AddressListThemeColor = [
  {
    label: "Header Bg Color",
    name: "addressListHeaderBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header in the address list page",
  },
  {
    label: "Header Font Color",
    name: "addressListHeaderFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for header title in the address list page",
  },
  {
    label: "Back Btn Bg Color",
    name: "addressListBackBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for header back button in the address list page",
  },
  {
    label: "Back Btn Icon Color",
    name: "addressListBackBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for header back icon in the address list page",
  },
  {
    label: "Bg Color",
    name: "addressListBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for address list page",
  },
  {
    label: "Font Color",
    name: "addressListFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for 'choose an address' text in the address list page",
  },
  {
    label: "Card Bg Color",
    name: "addressListCardBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for card sections in the address list page",
  },
  {
    label: "Card Primary Font Color",
    name: "addressListCardPrimaryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for address serial no. text in the card in the address list page",
  },
  {
    label: "Card Secondary Font Color",
    name: "addressListCardSecondaryFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for address text in the card in the address list page",
  },
  {
    label: "Card Edit Icon Color",
    name: "addressListCardEditIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for edit icon in the address list page",
  },
  {
    label: "Add Icon Bg Color",
    name: "addressListAddBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for add new address icon in the add address page",
  },
  {
    label: "Add Icon & Font Color",
    name: "addressListAddBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for add new address icon & text in the add address page",
  },
  {
    label: "Radio Btn Icon Color",
    name: "addressListRadioBtnIconColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Color for radio btn in the address list page",
  },
  {
    label: "Save Btn Bg Color",
    name: "addressListSaveBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for save button in the address list page",
  },
  {
    label: "Save Btn Font Color",
    name: "addressListSaveBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for save button in the address list page",
  },
  {
    label: "Cancel Btn Bg Color",
    name: "addressListCancelBtnBgColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Background color for cancel button in the address list page",
  },
  {
    label: "Cancel Btn Font Color",
    name: "addressListCancelBtnFontColor",
    value: "",
    defaultValue: null,
    type: "color",
    info: "Font color for cancel button in the address list page",
  },
];

const APPTYPE_LIST = [
  {label: 'FOODO (AR MENU LISTING ONLY)', value: APP_TYPE_FOODO},
  {label: 'GOPOSH (TABLE ORDER ONLY)', value: APP_TYPE_GOPOSH},
  {label: 'BOTH (AR MENU + TABLE ORDER)', value: APP_TYPE_BOTH}
];

const CustomRestaurantInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  const [headerBg, setHeaderBg] = useState("");
  const [arModelBg, setArModelBg] = useState("");
  const [bottomMenuIcon, setBottomMenuIcon] = useState("");
  const [printLogo, setPrintLogo] = useState("");
  const [nextIconVal, setNextIconVal] = useState("");
  const [prevIconVal, setPrevIconVal] = useState("");
  const [menuSectionBgVal, setMenuSectionBgVal] = useState("");

  const [menuItemFontBg, setMenuItemFontBg] = useState("");
  const [menuItemFontColor, setMenuItemFontColor] = useState("");
  const [menuItemFontName, setMenuItemFontName] = useState("");
  const [menuItemFontFile, setMenuItemFontFile] = useState("");

  const [modalBgColor, setModalBgColor] = useState("");
  const [modalFontColor, setModalFontColor] = useState("");
  const [userFormDataBg, setUserFormDataBg] = useState("");
  const [userFormDataLabelColor, setUserFormDataLabelColor] = useState("");
  const [userFormDataFontColor, setUserFormDataFontColor] = useState("");
  const [buttonBg, setButtonBg] = useState("");
  const [buttonTextBg, setButtonTextBg] = useState("");

  const [menuHeaderBg, setMenuHeaderBg] = useState("");

  const [menuListingThemes, setMenuListingThemes] =
    useState(MenuListThemeColor);
  const [menuPopupThemes, setMenuPopupThemes] = useState(
    MenuDetailPopThemeColor
  );
  const [cartThemes, setCartThemes] = useState(CartThemeColor);
  const [checkoutThemes, setCheckoutThemes] = useState(CheckoutThemeColor);
  const [orderSuccessThemes, setOrderSuccessThemes] = useState(
    OrderSuccessThemeColor
  );
  const [orderHistoryThemes, setOrderHistoryThemes] = useState(
    OrderHistoryThemeColor
  );
  const [orderDetailThemes, setOrderDetailThemes] = useState(
    OrderDetailThemeColor
  );
  const [addAddressThemes, setAddAddressThemes] =
    useState(AddAddressThemeColor);
  const [addressListThemes, setAddressListThemes] = useState(
    AddressListThemeColor
  );

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [imageType, setImageType] = useState("");

  const [tableOrder, setTableOrder] = useState(false);
  const [onlineOrder, setOnlineOrder] = useState(false);
  const [tablePayment, setTablePayment] = useState(false);
  const [wifiAvailable, setWifiAvailable] = useState(false);

  const [socialMediaList, setSocialMediaList] = useState([]);
  const [appType, setAppType] = useState(APPTYPE_LIST?.[0]?.value);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let data = restaurantDetail;
    let fontNameFile = null;
    if (menuItemFontName || menuItemFontFile) {
      fontNameFile = JSON.stringify({
        name: menuItemFontName,
        url: menuItemFontFile?.image ? menuItemFontFile?.image : "",
      });
    }

    const themeColors = data?.themeColors;

    let themeColor = {
      headerBg: themeColors?.headerBg ?? null,
      nextIcon: themeColors?.nextIcon ?? null,
      prevIcon: themeColors?.prevIcon ?? null,
      menuSectionBg: themeColors?.menuSectionBg ?? null,
    };

    [
      ...menuListingThemes,
      ...menuPopupThemes,
      ...cartThemes,
      ...checkoutThemes,
      ...orderSuccessThemes,
      ...orderHistoryThemes,
      ...orderDetailThemes,
      ...addAddressThemes,
      ...addressListThemes,
    ].forEach((theme) => {
      themeColor = {
        ...themeColor,
        [theme?.name]: themeColors?.[theme?.name] ?? theme.defaultValue,
      };
    });

    let media_list = [];
    socialMediaList.map((x, i) => {
      let media = {
        name: x?.name,
        action: x?.action,
        icon: x?.icon?.image ? x?.icon?.image : x?.icon ? x?.icon : null,
        data: x?.data,
      };
      if (x?._id) {
        media = {
          ...media,
          _id: x?._id,
        };
      }
      media_list.push(media);
    });

    let social_media_list = [];
    data?.socialMedias?.map((x, i) => {
      let media = {
        name: x?.name,
        action: x?.action,
        icon: x?.icon,
        data: x?.data,
      };
      if (x?._id) {
        media = {
          ...media,
          _id: x?._id,
        };
      }
      social_media_list.push(media);
    });

    let data1 = {
      headerBg: headerBg?.image ? headerBg.image : null,
      arModelBg: arModelBg?.image ? arModelBg.image : null,
      printLogo: printLogo?.image ?? "",
      bottomMenuIcon: bottomMenuIcon?.image ? bottomMenuIcon.image : null,
      menuItemFontBg: menuItemFontBg ? menuItemFontBg : null,
      menuItemFontColor: menuItemFontColor ? menuItemFontColor : null,
      menuItemNameFont: fontNameFile,
      userFormDataBg: userFormDataBg ? userFormDataBg : null,
      userFormDataLabelColor: userFormDataLabelColor
        ? userFormDataLabelColor
        : null,
      modalBgColor: modalBgColor ? modalBgColor : null,
      modalFontColor: modalFontColor ? modalFontColor : null,
      userFormDataFontColor: userFormDataFontColor
        ? userFormDataFontColor
        : null,
      buttonBg: buttonBg ? buttonBg : null,
      buttonTextBg: buttonTextBg ? buttonTextBg : null,
      tableOrder: tableOrder,
      onlineOrder: onlineOrder,
      tablePayment: tablePayment,
      wifiAvailable: wifiAvailable,
      socialMedias: media_list,
      appType: appType,
      themeColors: {
        headerBg: menuHeaderBg?.image ? menuHeaderBg?.image : null,
        nextIcon: nextIconVal?.image ?? null,
        prevIcon: prevIconVal?.image ?? null,
        menuSectionBg: menuSectionBgVal?.image ?? null,
      },
    };

    [
      ...menuListingThemes,
      ...menuPopupThemes,
      ...cartThemes,
      ...checkoutThemes,
      ...orderSuccessThemes,
      ...orderHistoryThemes,
      ...orderDetailThemes,
      ...addAddressThemes,
      ...addressListThemes,
    ].forEach((theme) => {
      data1["themeColors"] = {
        ...data1.themeColors,
        [theme?.name]: ["color", "fontsize"].includes(theme.type)
          ? theme?.value
            ? theme?.value
            : theme?.defaultValue
          : theme?.value,
      };
    });

    if (
      data?.headerBg !== data1.headerBg ||
      data?.arModelBg !== data1.arModelBg ||
      data?.printLogo !== data1.printLogo ||
      data?.bottomMenuIcon !== data1.bottomMenuIcon ||
      data?.menuItemFontBg !== data1.menuItemFontBg ||
      data?.menuItemFontColor !== data1.menuItemFontColor ||
      data?.menuItemNameFont !== data1.menuItemNameFont ||
      data?.userFormDataBg !== data1.userFormDataBg ||
      data?.userFormDataLabelColor !== data1.userFormDataLabelColor ||
      data?.modalBgColor !== data1.modalBgColor ||
      data?.modalFontColor !== data1.modalFontColor ||
      data?.userFormDataFontColor !== data1.userFormDataFontColor ||
      data?.buttonBg !== data1.buttonBg ||
      data?.buttonTextBg !== data1.buttonTextBg ||
      data?.tableOrder !== data1.tableOrder ||
      data?.onlineOrder !== data1.onlineOrder ||
      data?.tablePayment !== data1.tablePayment ||
      data?.wifiAvailable !== data1.wifiAvailable ||
      data?.appType !== data1.appType ||
      JSON.stringify(themeColor) !== JSON.stringify(data1.themeColors) ||
      JSON.stringify(social_media_list) !== JSON.stringify(data1.socialMedias)
    ) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [
    headerBg,
    arModelBg,
    printLogo,
    bottomMenuIcon,
    nextIconVal,
    prevIconVal,
    menuSectionBgVal,
    menuItemFontColor,
    menuItemFontBg,
    menuItemFontFile,
    menuItemFontName,
    userFormDataBg,
    userFormDataLabelColor,
    modalBgColor,
    modalFontColor,
    userFormDataFontColor,
    buttonBg,
    buttonTextBg,
    menuHeaderBg,
    tableOrder,
    onlineOrder,
    tablePayment,
    wifiAvailable,
    socialMediaList,
    appType,
    menuListingThemes,
    menuPopupThemes,
    cartThemes,
    checkoutThemes,
    orderSuccessThemes,
    orderHistoryThemes,
    orderDetailThemes,
    addAddressThemes,
    addressListThemes,
    restaurantDetail,
  ]);

  useEffect(() => {
    if (!RESTLIST.includes(restaurantId)) {
      setMenuItemFontBg("#2f5831");
      setMenuItemFontColor("#FFFFFF");
      setModalFontColor("#FFFFFF");
      setUserFormDataBg("#CA7128");
      setUserFormDataLabelColor("#FFFFFF");
      setUserFormDataFontColor("#FFFFFF");
      setButtonBg("#FFFFFF");
      setButtonTextBg("#D87025");
    }

    getRestaurantData();
  }, []);

  const getRestaurantData = () => {
    setLoading(true);
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        let resData = response.data;

        if (resData?.menuItemFontBg) {
          setMenuItemFontBg(resData.menuItemFontBg);
        }

        if (resData?.menuItemFontColor) {
          setMenuItemFontColor(resData.menuItemFontColor);
        }

        if (resData?.menuItemNameFont) {
          let fileNameFile = JSON.parse(resData.menuItemNameFont);
          setMenuItemFontName(fileNameFile?.name ? fileNameFile?.name : "");
          if (fileNameFile?.url) {
            setMenuItemFontFile(
              getImgDetail(fileNameFile.url, "menuItemFontFile")
            );
          }
        }

        let header_bg = resData?.headerBg;
        if (header_bg) {
          setHeaderBg(getImgDetail(header_bg, "headerBg"));
        }

        let ar_model_bg = resData?.arModelBg;
        if (ar_model_bg) {
          setArModelBg(getImgDetail(ar_model_bg, "arModelBg"));
        }

        const printLogoImg = resData?.printLogo;
        if (printLogoImg) {
          setPrintLogo(getImgDetail(printLogoImg, "printLogo"));
        }

        let bottom_menu_icon = resData?.bottomMenuIcon;
        if (bottom_menu_icon) {
          setBottomMenuIcon(getImgDetail(bottom_menu_icon, "bottomMenuIcon"));
        }

        if (resData?.userFormDataBg) {
          setUserFormDataBg(resData.userFormDataBg);
        }

        if (resData?.userFormDataLabelColor) {
          setUserFormDataLabelColor(resData.userFormDataLabelColor);
        }

        if (resData?.modalBgColor) {
          setModalBgColor(resData.modalBgColor);
        }

        if (resData?.modalFontColor) {
          setModalFontColor(resData.modalFontColor);
        }

        if (resData?.userFormDataFontColor) {
          setUserFormDataFontColor(resData.userFormDataFontColor);
        }

        if (resData?.buttonBg) {
          setButtonBg(resData.buttonBg);
        }

        if (resData?.buttonTextBg) {
          setButtonTextBg(resData.buttonTextBg);
        }

        if (resData?.themeColors) {
          let themeColor = resData?.themeColors;

          if (themeColor?.nextIcon) {
            setNextIconVal(getImgDetail(themeColor.nextIcon, "nextIcon"));
          }

          if (themeColor?.prevIcon) {
            setPrevIconVal(getImgDetail(themeColor.prevIcon, "prevIcon"));
          }

          if (themeColor?.menuSectionBg) {
            setMenuSectionBgVal(
              getImgDetail(themeColor.menuSectionBg, "menuSectionBg")
            );
          }

          if (themeColor?.headerBg) {
            setMenuHeaderBg(getImgDetail(themeColor.headerBg, "menuHeaderBg"));
          }

          const onSetTheme = (list) =>
            list.map((theme) => {
              if (themeColor.hasOwnProperty(theme?.name)) {
                const val = themeColor?.[theme?.name];
                return {
                  ...theme,
                  value:
                    theme.type === "switch" ? val : val ? val : theme?.value,
                };
              }

              return theme;
            });

          setMenuListingThemes(onSetTheme(menuListingThemes));
          setMenuPopupThemes(onSetTheme(menuPopupThemes));
          setCartThemes(onSetTheme(cartThemes));
          setCheckoutThemes(onSetTheme(checkoutThemes));
          setOrderSuccessThemes(onSetTheme(orderSuccessThemes));
          setOrderHistoryThemes(onSetTheme(orderHistoryThemes));
          setOrderDetailThemes(onSetTheme(orderDetailThemes));
          setAddAddressThemes(onSetTheme(addAddressThemes));
          setAddressListThemes(onSetTheme(addressListThemes));
        }

        setTableOrder(resData?.tableOrder);
        setOnlineOrder(resData?.onlineOrder);
        setTablePayment(resData?.tablePayment);
        setWifiAvailable(resData?.wifiAvailable);

        if (resData?.socialMedias?.length > 0) {
          setSocialMediaList(resData?.socialMedias);
        }

        if (resData?.appType) {
          setAppType(resData?.appType);
        }

        setRestaurantDetail(resData);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const getImgDetail = (file, type) => {
    let index1 = file.lastIndexOf("/") + 1;
    let filename = file.substr(index1);
    return {
      id: new Date() + type,
      name: filename,
      displayName: filename,
      size: 0,
      image: file,
      upload: true,
    };
  };

  const onAddEntry = () => {
    setSocialMediaList([
      ...socialMediaList,
      {
        name: "",
        action: "",
        icon: "",
        data: {
          url: "",
          iconHeight: "",
          iconWidth: "",
        },
      },
    ]);
  };

  const onUpdateSocialMedia = (field, value, index) => {
    let list = [...socialMediaList];
    let findIndex = list.findIndex((x, i) => i == index);
    if (findIndex >= 0) {
      if (["url", "iconHeight", "iconWidth"].includes(field)) {
        let data = {
          ...list[findIndex]?.data,
          [field]: value,
        };
        list[findIndex] = {
          ...list[findIndex],
          data: data,
        };
      } else {
        list[findIndex] = {
          ...list[findIndex],
          [field]: value,
        };
      }
      setSocialMediaList(list);
    }
  };

  const onDeleteSocialMedia = (index) => {
    setSocialMediaList(socialMediaList.filter((x, i) => i != index));
  };

  const onCheckColorFormatErr = (errParam, value) => {
    if (value && !colorValidation(value)) {
      return {
        [errParam]: "Invalid Color Code",
      };
    }
    return {};
  };

  const onSave = () => {
    let err = {};
    if (menuItemFontColor) {
      if (colorValidation(menuItemFontColor)) {
      } else {
        err = {
          ...err,
          menuItemFontColor: "Invalid Color Code",
        };
      }
    }

    if (menuItemFontBg) {
      if (colorValidation(menuItemFontBg)) {
      } else {
        err = {
          ...err,
          menuItemFontBg: "Invalid Color Code",
        };
      }
    }

    if (userFormDataBg) {
      if (colorValidation(userFormDataBg)) {
      } else {
        err = {
          ...err,
          userFormDataBg: "Invalid Color Code",
        };
      }
    }

    if (userFormDataLabelColor) {
      if (colorValidation(userFormDataLabelColor)) {
      } else {
        err = {
          ...err,
          userFormDataLabelColor: "Invalid Color Code",
        };
      }
    }

    if (modalBgColor) {
      if (colorValidation(modalBgColor)) {
      } else {
        err = {
          ...err,
          modalBgColor: "Invalid Color Code",
        };
      }
    }

    if (modalFontColor) {
      if (colorValidation(modalFontColor)) {
      } else {
        err = {
          ...err,
          modalFontColor: "Invalid Color Code",
        };
      }
    }

    if (userFormDataFontColor) {
      if (colorValidation(userFormDataFontColor)) {
      } else {
        err = {
          ...err,
          userFormDataFontColor: "Invalid Color Code",
        };
      }
    }

    if (buttonBg) {
      if (colorValidation(buttonBg)) {
      } else {
        err = {
          ...err,
          buttonBg: "Invalid Color Code",
        };
      }
    }

    if (buttonTextBg) {
      if (colorValidation(buttonTextBg)) {
      } else {
        err = {
          ...err,
          buttonTextBg: "Invalid Color Code",
        };
      }
    }

    [
      ...menuListingThemes,
      ...menuPopupThemes,
      ...cartThemes,
      ...checkoutThemes,
      ...orderSuccessThemes,
      ...orderHistoryThemes,
      ...orderDetailThemes,
      ...addAddressThemes,
      ...addressListThemes,
    ]
      .filter((x) => x?.type === "color")
      .forEach((theme) => {
        err = { ...err, ...onCheckColorFormatErr(theme?.name, theme?.value) };
      });

    let media_err = false;
    let mediaList = [];
    let list = [...socialMediaList];
    list.map((x, i) => {
      let err = null;
      if (x?.icon?.image || x?.icon) {
      } else {
        media_err = true;
        err = {
          ...err,
          icon: "Icon Required",
        };
      }

      if (x?.data?.url) {
      } else {
        media_err = true;
        err = {
          ...err,
          url: "Url Required",
        };
      }

      list[i] = { ...x, error: err };

      if (err) {
      } else {
        let icon_height = x?.data?.iconHeight?.replace(/[^0-9.]/g, "");
        let icon_width = x?.data?.iconWidth?.replace(/[^0-9.]/g, "");

        let data_data = {
          url: x?.data?.url,
        };

        if (x?.data?.iconHeight?.replace(/[^0-9.]/g, "")) {
          data_data = {
            ...data_data,
            iconHeight: icon_height,
          };
        }

        if (x?.data?.iconWidth?.replace(/[^0-9.]/g, "")) {
          data_data = {
            ...data_data,
            iconWidth: icon_width,
          };
        }

        let data = {
          name: x?.name,
          action: x?.action,
          icon: x?.icon?.image ? x?.icon?.image : x?.icon ? x?.icon : null,
          data: data_data,
        };
        if (x?._id) {
          data = { ...data, _id: x?._id };
        }
        mediaList.push(data);
      }
    });

    if (Object.getOwnPropertyNames(err).length > 0) {
      setError(err);
    }

    if (media_err) {
      setSocialMediaList(list);
    }

    if (Object.getOwnPropertyNames(err).length > 0 || media_err) {
      return;
    }

    let fontNameFile = null;
    if (menuItemFontName || menuItemFontFile) {
      fontNameFile = JSON.stringify({
        name: menuItemFontName,
        url: menuItemFontFile?.image ? menuItemFontFile?.image : "",
      });
    }

    let data = {
      headerBg: headerBg?.image ? headerBg.image : null,
      arModelBg: arModelBg?.image ? arModelBg.image : null,
      printLogo: printLogo?.image ?? "",
      bottomMenuIcon: bottomMenuIcon?.image ? bottomMenuIcon.image : null,
      menuItemFontBg: menuItemFontBg ? menuItemFontBg : null,
      menuItemFontColor: menuItemFontColor ? menuItemFontColor : null,
      menuItemNameFont: fontNameFile,
      userFormDataBg: userFormDataBg ? userFormDataBg : null,
      userFormDataLabelColor: userFormDataLabelColor
        ? userFormDataLabelColor
        : null,
      modalBgColor: modalBgColor ? modalBgColor : null,
      modalFontColor: modalFontColor ? modalFontColor : null,
      userFormDataFontColor: userFormDataFontColor
        ? userFormDataFontColor
        : null,
      buttonBg: buttonBg ? buttonBg : null,
      buttonTextBg: buttonTextBg ? buttonTextBg : null,
      themeColors: {
        headerBg: menuHeaderBg?.image ? menuHeaderBg?.image : null,
        nextIcon: nextIconVal?.image ?? null,
        prevIcon: prevIconVal?.image ?? null,
        menuSectionBg: menuSectionBgVal?.image ?? null,
      },
      tableOrder: tableOrder,
      onlineOrder: onlineOrder,
      tablePayment: tablePayment,
      wifiAvailable: wifiAvailable,
      socialMedias: mediaList,
      appType: appType,
    };

    [
      ...menuListingThemes,
      ...menuPopupThemes,
      ...cartThemes,
      ...checkoutThemes,
      ...orderSuccessThemes,
      ...orderHistoryThemes,
      ...orderDetailThemes,
      ...addAddressThemes,
      ...addressListThemes,
    ].forEach((theme) => {
      data["themeColors"] = {
        ...data.themeColors,
        [theme?.name]: ["color", "fontsize"].includes(theme.type)
          ? theme?.value
            ? theme?.value
            : theme?.defaultValue
          : theme?.value,
      };
    });

    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, restaurantId).then((response) => {
      if (response.success) {
        setRestaurantDetail({
          ...restaurantDetail,
          ...data,
        });
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const uploadLogoFile = (data, file, type) => {
    setImageType(type);
    fileUploadApi(file, data, type);
  };

  const fileUploadApi = (file, data, type) => {
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        if (type == "headerBg") {
          setHeaderBg(data);
        } else if (type == "arModelBg") {
          setArModelBg(data);
        } else if (type === "printLogo") {
          setPrintLogo(data);
        } else if (type == "bottomMenuIcon") {
          setBottomMenuIcon(data);
        } else if (type == "menuItemFontFile") {
          setMenuItemFontFile(data);
        } else if (type == "menuHeaderBg") {
          setMenuHeaderBg(data);
        } else if (type === "nextIcon") {
          setNextIconVal(data);
        } else if (type === "prevIcon") {
          setPrevIconVal(data);
        } else if (type === "menuSectionBg") {
          setMenuSectionBgVal(data);
        } else if (type.includes("socialMedia")) {
          let index = type.replace("socialMedia", "");
          if (index) {
            onUpdateSocialMedia("icon", data, Number(index));
          }
        }
        setImageLoading(false);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const colorValidation = (val) => {
    return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(val);
  };

  const handlerOnRemoveImg = (type) => {
    if (type === "printLogo") {
      setPrintLogo("");
    } else if (type === "headerBg") {
      setHeaderBg("");
    } else if (type === "menuHeaderBg") {
      setMenuHeaderBg("");
    } else if (type === "arModelBg") {
      setArModelBg("");
    } else if (type === "bottomMenuIcon") {
      setBottomMenuIcon("");
    } else if (type === "menuSectionBg") {
      setMenuSectionBgVal("");
    } else if (type === "prevIcon") {
      setPrevIconVal("");
    } else if (type === "nextIcon") {
      setNextIconVal("");
    }
  };

  const ColorView = ({ color }) => {
    return (
      <Box
        sx={{
          width: 18,
          height: 18,
          border: "0.5px solid #AAA",
          backgroundColor: color,
        }}
      />
    );
  };

  const handlerOnColorChange = (field, val) => {
    if (colorValidation(val) || val === "") {
      setError({ ...error, [field]: "" });
    } else {
      setError({
        ...error,
        [field]: "Invalid Color Code",
      });
    }

    if (field === "menuItemFontBg") {
      setMenuItemFontBg(val);
    } else if (field === "menuItemFontColor") {
      setMenuItemFontColor(val);
    } else if (field === "modalBgColor") {
      setModalBgColor(val);
    } else if (field === "modalFontColor") {
      setModalFontColor(val);
    } else if (field === "userFormDataBg") {
      setUserFormDataBg(val);
    } else if (field === "userFormDataLabelColor") {
      setUserFormDataLabelColor(val);
    } else if (field === "userFormDataFontColor") {
      setUserFormDataFontColor(val);
    } else if (field === "buttonBg") {
      setButtonBg(val);
    } else if (field === "buttonTextBg") {
      setButtonTextBg(val);
    }
  };

  const handlerOnChange = (fieldType, type, field, val) => {
    if (fieldType === "color") {
      if (colorValidation(val) || val === "") {
        setError({ ...error, [field]: "" });
      } else {
        setError({
          ...error,
          [field]: "Invalid Color Code",
        });
      }
    }
    let list = [];
    if (type === "menuListing") {
      list = [...menuListingThemes];
    } else if (type === "menuPopup") {
      list = [...menuPopupThemes];
    } else if (type === "cart") {
      list = [...cartThemes];
    } else if (type === "checkout") {
      list = [...checkoutThemes];
    } else if (type === "orderSuccess") {
      list = [...orderSuccessThemes];
    } else if (type === "orderHistory") {
      list = [...orderHistoryThemes];
    } else if (type === "orderDetail") {
      list = [...orderDetailThemes];
    } else if (type === "addAddress") {
      list = [...addAddressThemes];
    } else if (type === "addressList") {
      list = [...addressListThemes];
    }

    const findIndex = list.findIndex((x) => x.name === field);
    if (findIndex >= 0) {
      if (fieldType === "fontsize") {
        list[findIndex]["value"] = val.replace(/[^0-9.]/g, "");
      } else {
        list[findIndex]["value"] = val;
      }
    }

    if (type === "menuListing") {
      setMenuListingThemes(list);
    } else if (type === "menuPopup") {
      setMenuPopupThemes(list);
    } else if (type === "cart") {
      setCartThemes(list);
    } else if (type === "checkout") {
      setCheckoutThemes(list);
    } else if (type === "orderSuccess") {
      setOrderSuccessThemes(list);
    } else if (type === "orderHistory") {
      setOrderHistoryThemes(list);
    } else if (type === "orderDetail") {
      setOrderDetailThemes(list);
    } else if (type === "addAddress") {
      setAddAddressThemes(list);
    } else if (type === "addressList") {
      setAddressListThemes(list);
    }
  };

  const ToggleSwitchLabel = ({ label, info }) => {
    return (
      <Typography
        className={`${styles?.["toggle-switch-label"]}`}
        sx={{ path: { fill: "#000" }, mr: 1 }}
      >
        {label}
        {info ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={info}
            placement="top"
          >
            <InfoIcon
              height={15}
              width={15}
              style={{ cursor: "pointer", marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </Typography>
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {loading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Grid container item xs={12}>
            <Grid item xs={12} mb={2}>
              <RestaurantHeader
                title={"Portal Settings"}
                onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
              />
            </Grid>
            <Grid item xs={12} className={styles.mainView} px={3} pt={2}>
              <Grid
                mt={2}
                container
                direction="row"
                item
                xs={12}
                md={12}
                mb={2}
              >
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"printLogo"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={printLogo}
                    imageType={imageType}
                    placeholder={"PRINT LOGO"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("printLogo")}
                    info={InfoList?.printLogo}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"headerBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={headerBg}
                    imageType={imageType}
                    placeholder={"MAIN HEADER BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("headerBg")}
                    info={InfoList?.mainHeaderBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"menuHeaderBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={menuHeaderBg}
                    imageType={imageType}
                    placeholder={"HEADER BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("menuHeaderBg")}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"arModelBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={arModelBg}
                    imageType={imageType}
                    placeholder={"AR MODEL BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("arModelBg")}
                    info={InfoList?.arModelBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"bottomMenuIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={bottomMenuIcon}
                    imageType={imageType}
                    placeholder={"BOTTOM MENU ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("bottomMenuIcon")}
                    info={InfoList?.bottomMenuIcon}
                  />
                </Box>

                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"menuSectionBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={menuSectionBgVal}
                    imageType={imageType}
                    placeholder={"MENU SECTION IMG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("menuSectionBg")}
                    info={InfoList?.menuSectionBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"prevIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={prevIconVal}
                    imageType={imageType}
                    placeholder={"PREV ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("prevIcon")}
                    info={InfoList?.prevIcon}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"nextIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={nextIconVal}
                    imageType={imageType}
                    placeholder={"NEXT ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("nextIcon")}
                    info={InfoList?.nextIcon}
                  />
                </Box>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container alignItems="center" mt={1} mb={3}>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Online Order"}
                    info={InfoList?.onlineOrder}
                  />
                  <SwitchInput
                    checked={onlineOrder}
                    onChange={setOnlineOrder}
                  />
                </Box>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Table Order"}
                    info={InfoList?.tableOrder}
                  />
                  <SwitchInput checked={tableOrder} onChange={setTableOrder} />
                </Box>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Table Payment"}
                    info={InfoList?.tablePayment}
                  />
                  <SwitchInput
                    checked={tablePayment}
                    onChange={setTablePayment}
                  />
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                  <ToggleSwitchLabel
                    label={"Wifi Available"}
                    info={InfoList?.wifiAvailable}
                  />
                  <SwitchInput
                    checked={wifiAvailable}
                    onChange={setWifiAvailable}
                  />
                </Box>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container item xs={12}>
                <Grid item xs={12} md={3} mt={2} mb={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      App Type
                    </Typography>
                  </Box>
                  <Box>
                    <SettingSelectField
                      page={"customRestaurantInfo"}
                      value={appType}
                      setValue={setAppType}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      menuList={APPTYPE_LIST}
                      error={error?.appType}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    AR Page
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Menu Item Font Color"}
                    field={"menuItemFontColor"}
                    value={menuItemFontBg}
                    info={InfoList?.menuItemFontColor ?? ""}
                    error={error?.menuItemFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Menu Item Font Bg"}
                    field={"menuItemFontBg"}
                    value={menuItemFontBg}
                    info={InfoList?.menuItemFontBg ?? ""}
                    error={error?.menuItemFontBg}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font Name
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={menuItemFontName}
                      setValue={(val) => setMenuItemFontName(val)}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font File
                    </Typography>
                  </Box>
                  <Box>
                    <SettingFileInput
                      id={"menuFontFileButton"}
                      type={"menuItemFontFile"}
                      value={menuItemFontFile?.name}
                      setValue={() => null}
                      uploadFile={fileUploadApi}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontFile}
                      onRemove={() => setMenuItemFontFile("")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Modal Bg Color"}
                    field={"menuItemFontColor"}
                    value={modalBgColor}
                    info={InfoList?.modalBgColor ?? ""}
                    error={error?.modalBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Modal Font Color"}
                    field={"modalFontColor"}
                    value={modalFontColor}
                    info={InfoList?.modalFontColor ?? ""}
                    error={error?.modalFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"User Form Data Bg"}
                    field={"userFormDataBg"}
                    value={userFormDataBg}
                    info={InfoList?.userFormDataBg ?? ""}
                    error={error?.userFormDataBg}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"User Form Data Label Color"}
                    field={"userFormDataLabelColor"}
                    value={userFormDataLabelColor}
                    info={InfoList?.userFormDataLabelColor ?? ""}
                    error={error?.userFormDataLabelColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"User Form Data Font Color"}
                    field={"userFormDataFontColor"}
                    value={userFormDataFontColor}
                    info={InfoList?.userFormDataFontColor ?? ""}
                    error={error?.userFormDataFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Button Bg"}
                    field={"buttonBg"}
                    value={buttonBg}
                    info={InfoList?.buttonBg ?? ""}
                    error={error?.buttonBg}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={2}>
                  <PortalSettingsColorInput
                    label={"Button Text Bg"}
                    field={"buttonTextBg"}
                    value={buttonTextBg}
                    info={InfoList?.buttonTextBg ?? ""}
                    error={error?.buttonTextBg}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Menu Listing Page
                  </Typography>
                </Grid>
                {menuListingThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "menuListing",
                              field,
                              val
                            )
                          }
                        />
                      ) : input.type === "switch" ? (
                        <Box>
                          <ToggleSwitchLabel
                            label={input?.label}
                            info={input?.info}
                          />
                          <Box mt={2}>
                            <SwitchInput
                              checked={input?.value}
                              onChange={(val) =>
                                handlerOnChange(
                                  input?.type,
                                  "menuListing",
                                  input?.name,
                                  val
                                )
                              }
                            />
                          </Box>
                        </Box>
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Menu Detail Popup
                  </Typography>
                </Grid>
                {menuPopupThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "menuPopup",
                              field,
                              val
                            )
                          }
                        />
                      ) : input.type === "fontsize" ? (
                        <>
                          <Box mb={1}>
                            <Typography className={styles.textInputLabel}>
                              {input?.label}
                            </Typography>
                          </Box>
                          <SettingTextField
                            page={"customRestaurantInfo"}
                            editable={true}
                            value={input?.value}
                            setValue={(val) =>
                              handlerOnChange(
                                input?.type,
                                "menuPopup",
                                input?.name,
                                val
                              )
                            }
                            placeholder={""}
                            required={true}
                            variant={"filled"}
                            border={"1px solid #AAA"}
                            error={error?.[input?.name]}
                          />
                        </>
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Cart Page
                  </Typography>
                </Grid>
                {cartThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(input?.type, "cart", field, val)
                          }
                        />
                      ) : input.type === "fontsize" ? (
                        <>
                          <Box mb={1}>
                            <Typography className={styles.textInputLabel}>
                              {input?.label}
                            </Typography>
                          </Box>
                          <SettingTextField
                            page={"customRestaurantInfo"}
                            editable={true}
                            value={input?.value}
                            setValue={(val) =>
                              handlerOnChange(
                                input?.type,
                                "cart",
                                input?.name,
                                val
                              )
                            }
                            placeholder={""}
                            required={true}
                            variant={"filled"}
                            border={"1px solid #AAA"}
                            error={error?.[input?.name]}
                          />
                        </>
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Checkout Page
                  </Typography>
                </Grid>
                {checkoutThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(input?.type, "checkout", field, val)
                          }
                        />
                      ) : input.type === "fontsize" ? (
                        <>
                          <Box mb={1}>
                            <Typography className={styles.textInputLabel}>
                              {input?.label}
                            </Typography>
                          </Box>
                          <SettingTextField
                            page={"customRestaurantInfo"}
                            editable={true}
                            value={input?.value}
                            setValue={(val) =>
                              handlerOnChange(
                                input?.type,
                                "checkout",
                                input?.name,
                                val
                              )
                            }
                            placeholder={""}
                            required={true}
                            variant={"filled"}
                            border={"1px solid #AAA"}
                            error={error?.[input?.name]}
                          />
                        </>
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Order Success Page
                  </Typography>
                </Grid>
                {orderSuccessThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "orderSuccess",
                              field,
                              val
                            )
                          }
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Order History Page
                  </Typography>
                </Grid>
                {orderHistoryThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "orderHistory",
                              field,
                              val
                            )
                          }
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Order Detail Page
                  </Typography>
                </Grid>
                {orderDetailThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "orderDetail",
                              field,
                              val
                            )
                          }
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Add Address Page
                  </Typography>
                </Grid>
                {addAddressThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "addAddress",
                              field,
                              val
                            )
                          }
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container mt={2} xs={12} md={12} mb={2} spacing={2}>
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Address List Page
                  </Typography>
                </Grid>
                {addressListThemes.map((input, index) => {
                  return (
                    <Grid item xs={12} md={3} mt={2} key={index}>
                      {input.type === "color" ? (
                        <PortalSettingsColorInput
                          label={input?.label}
                          field={input?.name}
                          value={input?.value}
                          info={input?.info}
                          error={error?.[input?.name]}
                          handlerOnColorChange={(field, val) =>
                            handlerOnChange(
                              input?.type,
                              "addressList",
                              field,
                              val
                            )
                          }
                        />
                      ) : null}
                    </Grid>
                  );
                })}
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid item container xs={12} mt={3}>
                <Typography className={styles.textInputLabel}>
                  Social Media
                </Typography>
              </Grid>
              <Grid
                mt={1}
                container
                direction="row"
                justifyContent={"space-between"}
                sx={{ border: "1px solid #AAA", borderRadius: 5 }}
                item
                xs={12}
                md={12}
                mb={2}
                py={2}
                px={3}
              >
                {socialMediaList.map((x, i) => {
                  return (
                    <SocialMedia
                      key={i}
                      index={i}
                      data={x}
                      imageLoading={imageLoading}
                      imageType={imageType}
                      uploadLogoFile={uploadLogoFile}
                      getImgDetail={getImgDetail}
                      onUpdate={onUpdateSocialMedia}
                      onDelete={onDeleteSocialMedia}
                    />
                  );
                })}
              </Grid>
              <Grid container item xs={12} justifyContent={"flex-end"} mt={-1}>
                <Button
                  type="button"
                  disabled={false}
                  className={styles.addEntry}
                  onClick={onAddEntry}
                >
                  Add Entry
                </Button>
              </Grid>
              <Grid container direction="row" item xs={12} md={12} mb={2}>
                <Button
                  type="button"
                  disabled={saveBtnDisabled || imageLoading}
                  className={styles.saveBtn}
                  sx={{
                    opacity: saveBtnDisabled || imageLoading ? 0.2 : 1,
                  }}
                  onClick={saveBtnDisabled || imageLoading ? null : onSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default CustomRestaurantInfo;
