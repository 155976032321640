export const API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const THEME_COLOR = "#ffe342";
export const THEME_COLOR2 = "#f14136";
export const THEME_COLOR3 = "#AAA";
export const THEME_COLOR4 = "#C4C4C4";
export const THEME_COLOR5 = "#272D2F";
export const LOADING_COLOR = "#000";
export const BTN_COLOR = "#000";
export const BTN_TEXT_COLOR = "#FFF";

export const RESTAURANT_APP_URL = process.env.REACT_APP_RESTAURANT_APP_URL;

export const CURRENCY_LIST = [
  { label: "GBP - £", value: "gbp", currency: "GBP", symbol: "£" },
  { label: "Euro - €", value: "euro", currency: "EUR", symbol: "€" },
  {
    label: "Dirham - د. إ.",
    value: "dirham",
    currency: "AED",
    symbol: "د. إ.",
  },
  { label: "Rupee - ₹", value: "rupee", currency: "INR", symbol: "₹" },
  {
    label: "Bahraini Dinar - .د.ب",
    value: "bahraini dinar",
    currency: "BD",
    symbol: ".د.ب",
  },
  {
    label: "Saudi Riyal - ﷼",
    value: "saudi riyal",
    currency: "SR",
    symbol: "﷼",
  },
];

export const CURRENCY_SYMBOL = CURRENCY_LIST.reduce(
  (o, key) => ({ ...o, [key.currency]: key.symbol }),
  {}
);

export const MENU_TYPE = [
  "None",
  "Non vegetarian",
  "Vegetarian",
  "Plant based",
  "Seafood",
];

export const DINEIN = 1;
export const DELIVERY = 2;
export const PICKUP = 3;
export const TABLE = 4;

export const APP_TYPE_FOODO = "FOODO";
export const APP_TYPE_GOPOSH = "GOPOSH";
export const APP_TYPE_BOTH = "BOTH";

export const COUNTRY_LIST = [
  "United Kingdom",
  "Ireland",
  "India",
  "Germany",
  "Turkey",
  "Saudi Arabia",
  "United Arab Emirates (UAE)",
  "Qatar",
];
