import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import "./../assets/css/custom.css";

import { RESTAURANT_APP_URL, THEME_COLOR2 } from "../constants";

const RestaurantList = (props) => {
  let data = props.data;

  let url = `${RESTAURANT_APP_URL}restaurant_menu/${data._id}`;
  if (data?.slug) {
    url = `${RESTAURANT_APP_URL}r/${data.slug}`;
  }
  return (
    <Card
      sx={[styles.mainGrid, data.selected ? styles.selected : ""]}
      onClick={() => props.onSelectRestaurant(data)}
    >
      <Grid container>
        <Grid container item xs={12} md={1} alignItems="center">
          <Grid
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              height: { xs: 80, md: 75 },
              width: { xs: "100%", md: "85%" },
            }}
          >
            <img src={data.images[0]} style={styles.restaurantImage} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          direction="row"
          sx={{ marginTop: { xs: 1, md: 0 } }}
        >
          <Grid container item xs={12} direction="column">
            <Box display="flex" alignItems="center" mb={1}>
              <Typography sx={styles.nameText}>{data.name}</Typography>
              {data?.accountDetails?.subscriptionsName ? (
                <Typography sx={styles.planText}>
                  {data?.accountDetails?.subscriptionsName}
                </Typography>
              ) : null}
            </Box>
            <Typography sx={styles.descText}>
              {data.address
                ? `${data.address.firstLine}, ${data.address.secondLine}, ${data.address.countryCode}, ${data.address.postCode}`
                : null}
            </Typography>
            <Grid container direction="row" alignItems="center">
              {data.address ? (
                <>
                  <Typography sx={styles.placeText}>
                    {data.address ? data.address.city : ""}
                  </Typography>
                  <Box sx={styles.dotBox} />
                  <Typography sx={styles.distanceText}>0 km</Typography>
                </>
              ) : null}
            </Grid>
            <Box
              style={styles.linkText}
              onClick={(e) => {
                e.stopPropagation();
                window.open(url, "_blank");
              }}
            >
              {url}
            </Box>
            <Typography sx={styles.knowMoreText}>know more</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={1} alignItems="center">
          <Grid
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              height: { xs: 80, md: 75 },
              width: { xs: "100%", md: "85%" },
            }}
          >
            <img
              src={require("../assets/images/qr_code.png")}
              style={styles.restaurantImage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RestaurantList;

const styles = {
  mainGrid: {
    borderRadius: 3,
    width: "100%",
    marginBottom: 2,
    padding: 1.3,
    cursor: "pointer",
  },
  selected: {
    border: "2px solid " + THEME_COLOR2,
  },
  restaurantImage: {
    width: "100%",
    height: "100%",
  },
  nameText: {
    color: THEME_COLOR2,
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  planText: {
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 12,
    ml: 1,
    backgroundColor: "#ffe342",
    borderRadius: 1,
    px:1
  },
  descText: {
    color: "#888",
    fontFamily: "GilroyMedium",
    fontSize: 11,
    lineHeight: 1,
  },
  placeText: {
    color: "#888",
    fontFamily: "GilroyMedium",
    fontSize: 11,
  },
  dotBox: {
    backgroundColor: "#000",
    border: "1px solid #888",
    borderRadius: 50,
    height: 3,
    width: 3,
    ml: 0.5,
    mr: 0.5,
  },
  distanceText: {
    color: "#888",
    fontFamily: "GilroySemiBold",
    fontSize: 11,
  },
  linkText: {
    color: "#0071bc",
    fontFamily: "GilroySemiBold",
    fontSize: 11.5,
    textDecoration: "underline",
    width: "fit-content",
  },
  knowMoreText: {
    color: "#888",
    fontFamily: "GilroySemiBold",
    fontSize: 11.5,
    textDecoration: "underline",
  },
};
