import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";

import {connect} from "react-redux";
import {updateDrawerOpen} from "../redux/actions/userDataActions";

import {ReactComponent as Settings} from "../assets/images/settings.svg";
import {ReactComponent as Users} from "../assets/images/users.svg";
import {ReactComponent as Events} from "../assets/images/events.svg";
import {ReactComponent as Restaurant} from "../assets/images/restaurant.svg";
import {ReactComponent as CategoryIcon} from "../assets/images/category.svg";
import {ReactComponent as Logo} from "../assets/images/logo.svg";

import Navbar from "./Navbar";

import {THEME_COLOR, THEME_COLOR3} from "../constants";

const drawerWidth = 240;
const drawerWindowWidth = 75;

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const menu_list = [
  {page: 'events', label: 'Events', icon: <Events height={30} width={30}/>, href: '/'},
  {page: 'restaurants', label: 'Restaurants', icon: <Restaurant height={30} width={30}/>, href: '/restaurants'},
  {page: 'categories', label: 'Categories', icon: <CategoryIcon height={30} width={30}/>, href: '/categories'},
  {page: 'users', label: 'Users', icon: <Users height={30} width={30}/>, href: '/users'},
  {page: 'event_requests', label: 'Events Requests', icon: <Settings height={30} width={30}/>, href: '/'},
];

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(props.drawerOpen);
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setMobileView(window.innerWidth <= 990);
    });
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    props.updateDrawerOpen(!mobileOpen);
  };

  const SideDrawer = () => {
    return (
      <Box sx={styles.mainBox}>
        <DrawerHeader style={{justifyContent: 'center', marginBottom: '20%'}}>
          <Logo width={'70%'} height={'70%'}/>
        </DrawerHeader>
        <List style={{paddingLeft: !mobileOpen ? 10 : 25}}>
          {menu_list.map(x => {
            return (
              <ListItem
                button
                selected={props.page === x.page}
                key={x.label}
                className={props.page === x.page ? '' : 'sidebar-not-active-hover'}
                style={props.page === x.page ? {
                  backgroundColor: '#EEE',
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20
                } : null}
                sx={styles.listItem}
                onClick={() => navigate(x.href)}>
                <ListItemIcon
                  sx={styles.listItemIcon}>
                  {x.icon}
                </ListItemIcon>
                <ListItemText primary={x.label}
                              sx={{
                                '& .MuiTypography-root': {
                                  fontFamily: 'GilroySemiBold',
                                  fontSize: 14
                                }, opacity: 1,
                              }}/>
                {props.page === x.page ?
                  <>
                    <div className="hover-top-cut"></div>
                    <div className="hover-top-white"></div>
                    <div className="hover-bottom-cut"></div>
                    <div className="hover-bottom-white"></div>
                  </> : null}
              </ListItem>
            )
          })}
        </List>
      </Box>
    )
  };

  const container = props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>
      <Navbar
        open={mobileOpen}
        handleDrawer={handleDrawerToggle}
        {...props}
      />
      <Box
        component="nav"
        sx={{width: {sm: !mobileOpen ? drawerWindowWidth : drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileView ? mobileOpen : false}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: !mobileOpen ? drawerWindowWidth : drawerWidth,
              borderRightWidth: 0
            },
          }}>
          <SideDrawer/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: !mobileOpen ? drawerWindowWidth : drawerWidth,
              borderRightWidth: 0
            },
          }}
          open>
          <SideDrawer/>
        </Drawer>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data))
  }
};

const mapStateToProps = state => {
  return {
    drawerOpen: state.userData.drawerOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

const styles = {
  mainBox: {
    backgroundColor: THEME_COLOR,
    paddingTop: 1,
    height: '100%',
    overflow: 'hidden',
  },
  listItem: {
    justifyContent: 'initial',
    height: 48,
    px: 2.5,
  },
  listItemIcon: {
    justifyContent: 'center',
    minWidth: 0,
    mr: 3,
    zIndex: 3,
  },
};
