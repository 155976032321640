import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ReactComponent as MenuImg } from "../../assets/images/file.svg";

import { getCartItemsTotalPrice, isObject } from "../../constants/CommonFunc";

const OrderModalItemList = (props) => {
  let item = props.item;

  let modifiers = [];
  const totalPrice = getCartItemsTotalPrice(item, []) ?? 0;
  item?.cartModifier?.map((mod1, i1) => {
    if (isObject(mod1)) {
      modifiers.push([mod1?.withExtra + " " + mod1?.menuData?.name]);
    } else {
      const modifier = Array.isArray(mod1) ? mod1 : JSON.parse(mod1);
      const mod = modifier?.map((y1) => {
        return y1?.withExtra + " " + y1?.menuData?.name;
      });
      if (mod.length) {
        modifiers.push(mod);
      }
    }
  });

  return (
    <Box px={3} pt={2} sx={styles.mainView}>
      <Box mr={2}>
        {item.images?.length ? (
          <Box sx={styles.imageView}>
            <img src={item.images[0]} style={styles.imageStyle} />
          </Box>
        ) : (
          <Box sx={{ ...styles.imageView, ...styles.menuImgView }}>
            <MenuImg width={"40%"} height={"40%"} />
          </Box>
        )}
      </Box>
      <Box
        pb={2}
        sx={{
          ...styles.dataView,
          borderBottom: props.lastIndex ? 0 : "1px solid #DEDEDE",
        }}
      >
        <Box mb={1} sx={styles.nameAddOnView}>
          <Typography sx={styles.nameText}>{item.name}</Typography>
        </Box>
        {modifiers.length
          ? modifiers.map((y2, i2) => {
              return (
                <Typography
                  key={i2}
                  mt={1}
                  sx={styles.noteText}
                  style={{ color: "#272D2F" }}
                >
                  -- 1 {y2.join(", ")}
                </Typography>
              );
            })
          : null}

        {item?.notes ? (
          <Typography my={1} sx={styles.noteText}>
            Notes : {item.notes}
          </Typography>
        ) : null}
        <Box sx={styles.priceQtyView}>
          <Typography sx={styles.priceText}>
            {item?.quantity}x {item?.price?.currency}: {totalPrice?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderModalItemList;

const styles = {
  mainView: {
    display: "flex",
    flexDirection: "row",
  },
  dataView: {
    flex: 1,
  },
  imageView: {
    width: 56,
    height: 56,
    borderRadius: 28,
    overflow: "hidden",
  },
  menuImgView: {
    backgroundColor: "#EEEEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  nameAddOnView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameText: {
    color: "#000000",
    fontFamily: "GilroyBold",
    fontSize: 16,
  },
  addOnView: {
    border: "1px solid #DEDEDE",
    borderRadius: 37,
    px: 1,
  },
  addOnText: {
    color: "#000000",
    fontFamily: "GilroyBold",
    fontSize: 12,
  },
  noteText: {
    color: "#000000",
    fontFamily: "GilroyMedium",
    fontSize: 12,
    lineHeight: "13px",
  },
  priceQtyView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceText: {
    color: "#000000",
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  qtyText: {
    color: "#000000",
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
};
