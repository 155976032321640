import React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import "./../assets/css/custom.css";

const theme = createTheme({
  palette: {
    gray: {
      main: "#868E96",
    },
  },
});

const SettingSelectField = (props) => {
  const { labelField = "", valueField = "" } = props;
  let bgColor =
    props?.editable !== false
      ? props?.backgroundColor
        ? props.backgroundColor
        : "#FFF"
      : "#DDDDDD";

  const handleValueOnChange = (e) => {
    props.setValue(e.target.value);
  };

  return (
    <Box>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[styles.labelText, props?.labelStyle ? props.labelStyle : null]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          ...styles.textInputView,
          ...props?.textInputViewStyle,
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          border: props?.border ? props.border : "0px",
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            select
            required={props?.required ? props.required : false}
            disabled={!(props?.editable == false ? false : true)}
            label={
              props?.placeholder && props.value === "" ? props?.placeholder : ""
            }
            color="gray"
            fullWidth
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={handleValueOnChange}
            sx={{
              "& .MuiSelect-select": {
                "&:focus": {
                  backgroundColor: bgColor,
                },
              },
              "& .MuiInputBase-root": {
                "&:hover": {
                  backgroundColor: bgColor,
                },
                "&.Mui-focused": {
                  backgroundColor: bgColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: bgColor,
                },
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
              },
              "& .MuiInputAdornment-positionStart": {
                margin: "0 !important",
              },
              "& .MuiFilledInput-input": {
                py: 0,
                px: 1.5,
              },
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                ...styles.inputLabelStyle,
                "& .MuiFormLabel-asterisk": {
                  display: "none",
                },
              },
            }}
            InputProps={{
              autoComplete: "none",
              sx: { ...styles.textInputValue, backgroundColor: bgColor },
              startAdornment: props?.leftView ? (
                <InputAdornment position="start">
                  {props.leftView}
                </InputAdornment>
              ) : null,
              endAdornment: props?.rightView ? (
                <InputAdornment position="end">
                  {props.rightView}
                </InputAdornment>
              ) : null,
            }}
          >
            {props.menuList.map((x, i) => {
              return (
                <MenuItem
                  key={i}
                  sx={styles.menuItem}
                  divider={i < props.menuList.length - 1}
                  value={
                    typeof x === "string"
                      ? x
                      : valueField
                      ? x?.[valueField]
                      : x?.value
                  }
                >
                  {typeof x === "string"
                    ? x
                    : labelField
                    ? x?.[labelField]
                    : x?.label}
                </MenuItem>
              );
            })}
          </TextField>
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography sx={styles.errorText}>{props.error}</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingSelectField);

const styles = {
  labelText: {
    color: "#272D2F",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  textInputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    overflow: "hidden",
  },
  inputLabelStyle: {
    fontSize: 17,
    fontFamily: "GilroyMedium",
    px: 0,
    mt: 0.8,
  },
  textInputValue: {
    height: 46,
    color: "#000000",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
    px: 0,
  },
  menuItem: {
    color: "#272D2F",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  errorText: {
    color: "#CD0000",
    fontSize: 10,
    fontFamily: "GilroyMedium",
  },
};
