import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import AlertMsg from "./AlertMsg";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const AddRestaurantCategoryModal = ({ open, editData, onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "",
  });

  useEffect(() => {
    setIsLoading(false);
    if (editData) {
      setName(editData?.name);
    } else {
      setName("");
    }
    setMsgAlert({
      open: false,
      message: "",
      msgType: "error",
    });
  }, [editData]);

  const onSubmitCategory = () => {
    let err = null;
    if (name.trim() === "") {
      err = { name: "Name is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    createRestCategory();
  };

  const createRestCategory = () => {
    setIsLoading(true);
    const itemId = editData?._id ?? null;
    const data = {
      name: name,
    };
    let datas = JSON.stringify(data);
    Api.createRestaurantCategory(datas, itemId).then((response) => {
      if (response.success) {
        if (itemId) {
          onSubmit({
            ...editData,
            ...data,
          });
        } else {
          onSubmit(response.data);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: 0,
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "GilroyBold",
              fontSize: 18,
            }}
          >
            {editData?._id ? "Edit" : "Add"} Category
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
        </Box>

        <Box mt={2}>
          <SettingTextField
            page={"AddRestaurantCategoryModal"}
            editable={true}
            value={name}
            setValue={(val) => setName(val)}
            placeholder={"Name"}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            borderRadius={"5px"}
            error={error?.name}
          />
        </Box>
        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            type="button"
            disabled={isLoading}
            sx={{
              backgroundColor: "#000",
              color: "#FFF",
              width: 100,
              opacity: isLoading ? 0.2 : 1,
              fontFamily: "GilroyBold",
              fontSize: 14,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "#000 !important",
              },
            }}
            onClick={isLoading ? null : onSubmitCategory}
          >
            {editData?._id ? "Update" : "Save"}
          </Button>
        </Box>
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Box>
    </Modal>
  );
};

export default AddRestaurantCategoryModal;
