import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import UserList from "../../components/UserList";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import AddUserModal from "../../components/AddUserModal";
import ConfirmAlert from "../../components/restaurant/ConfirmAlert";

import "../../assets/css/custom.css";

import { THEME_COLOR3, THEME_COLOR4 } from "../../constants";

const Users = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [confirmDeleteData, setConfirmDeleteData] = useState(null);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "",
  });

  useEffect(() => {
    getUserList(1);
  }, []);

  const onSelectPage = (value) => {
    getUserList(value);
  };

  const getUserList = (pageNo) => {
    setIsLoading(true);
    let filter = `?pageSize=20&pageNum=${pageNo}&filter_status=1`;
    Api.getUserList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setUserList(data);
        setPageNum(pageNo);
        setTotalPages(response.data.pages);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onAddEdit = (item) => {
    setEditData(item);
    setAddModalVisible(true);
  };

  const onChangePswd = (item) => {
    setEditData({ editType: "change_pswd", ...item });
    setAddModalVisible(true);
  };

  const onCloseModal = () => {
    setAddModalVisible(false);
    setEditData(null);
  };

  const onSubmit = (data) => {
    let list = [...userList];
    const findIndex = list.findIndex((x) => x?._id === data?._id);
    if (findIndex >= 0) {
      list[findIndex] = { ...list[findIndex], ...data };
    } else {
      list.push(data);
    }
    setUserList(list);
    setAddModalVisible(false);
    setEditData(null);
  };

  const onDeleteUser = (item) => {
    setConfirmBtnLoading(false);
    setConfirmDeleteData(item);
  };

  const deleteUser = () => {
    if (confirmDeleteData?._id && confirmDeleteData?._id !== props.userId) {
      setConfirmBtnLoading(true);
      Api.addUpdateUser({ status: 0 }, confirmDeleteData?._id).then(
        (response) => {
          if (response.success) {
            setUserList(
              userList?.filter((x) => x?._id !== confirmDeleteData?._id)
            );
            onCloseConfirmDelete();
          } else {
            setConfirmBtnLoading(false);
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
          }
        }
      );
    } else {
      onCloseConfirmDelete();
    }
  };

  const onCloseConfirmDelete = () => {
    setConfirmBtnLoading(false);
    setConfirmDeleteData(null);
  };

  return (
    <Container
      page={"users"}
      searchVal={""}
      onSearch={() => null}
      searchLabel="Users"
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Box sx={styles.titleMainBox}>
            <Typography variant={"h6"} sx={styles.userText}>
              Users
            </Typography>

            <Button
              type="button"
              sx={{
                backgroundColor: "#000",
                color: "#FFF",
                width: 100,
                fontFamily: "GilroyBold",
                fontSize: 14,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#000 !important",
                },
              }}
              onClick={() => onAddEdit(null)}
            >
              + ADD
            </Button>
          </Box>

          <Grid container direction="row" mt={2}>
            {userList.map((item, index) => {
              return (
                <UserList
                  key={index}
                  data={item}
                  userId={props.userId}
                  onChangePswd={onChangePswd}
                  onDelete={onDeleteUser}
                />
              );
            })}
          </Grid>
          <Page
            totalPages={totalPages}
            pageNum={pageNum}
            onSelectPage={onSelectPage}
          />
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
      <AddUserModal
        open={addModalVisible}
        editData={editData}
        onClose={onCloseModal}
        onSubmit={onSubmit}
      />
      <ConfirmAlert
        confirmDelete={confirmDeleteData !== null}
        title={"Are you sure?"}
        content={"You will not be able to recover this user"}
        firstBtn={"No"}
        secondBtn={confirmBtnLoading ? "Please Wait" : "Yes ! Delete it"}
        btnLoading={confirmBtnLoading}
        onCloseConfirmDelete={onCloseConfirmDelete}
        onConfirmDelete={deleteUser}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.userData.userId,
  };
};

export default connect(mapStateToProps, null)(Users);

const styles = {
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 2,
  },
  userText: {
    fontFamily: "GilroyBold",
  },
  mainGrid: {
    cursor: "pointer",
    borderRadius: 3,
    width: "100%",
    marginBottom: 2,
    padding: 1.3,
  },
  labelTitle: {
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  selectList: {
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      fontFamily: "GilroySemiBold",
      fontSize: 13,
      paddingTop: 0.8,
      paddingBottom: 0.8,
    },
  },
  selectListItem: {
    fontFamily: "GilroySemiBold",
    color: THEME_COLOR3,
    fontSize: 13,
  },
};
