import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import AlertMsg from "./AlertMsg";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const AddUserModal = ({ open, editData, onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nameVal, setNameVal] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [pswd, setPswd] = useState("");
  const [confirmPswd, setConfirmPswd] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "",
  });

  useEffect(() => {
    setIsLoading(false);
    if (editData) {
      const { name, email } = editData;
      setNameVal(name);
      setEmailVal(email);
    } else {
      setNameVal("");
      setEmailVal("");
    }
    setError(null);
    setPswd("");
    setConfirmPswd("");
    setMsgAlert({
      open: false,
      message: "",
      msgType: "error",
    });
  }, [editData, open]);

  const onSubmitUser = () => {
    let err = null;
    if (nameVal.trim() === "") {
      err = { name: "Name is required" };
    }

    if (emailVal.trim() === "") {
      err = { ...err, email: "Email is required" };
    } else {
      const reg = /^[\w+]+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(emailVal) === false) {
        err = { ...err, email: "Invalid Email" };
      }
    }

    if (pswd.trim() === "") {
      err = { ...err, pswd: "Password is required" };
    } else {
      if (pswd.length < 8) {
        err = {
          ...err,
          pswd: "Password must contain at least 8 characters",
        };
      } else {
        if (pswd.trim() !== confirmPswd.trim()) {
          err = { ...err, confirmPswd: "Password Mismatch" };
        }
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    addEditUser();
  };

  const addEditUser = () => {
    setIsLoading(true);
    const itemId = editData?._id ?? null;
    let data = {
      name: nameVal,
      email: emailVal,
      password: pswd,
    };

    if (editData?.editType === "change_pswd") {
      data = {
        password: pswd,
      };
    }

    let datas = JSON.stringify(data);
    Api.addUpdateUser(datas, itemId).then((response) => {
      if (response.success) {
        if (itemId) {
          onSubmit({
            ...editData,
            ...data,
          });
        } else {
          onSubmit(response.data);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const handlerOnChange = (type, val) => {
    if (type === "name") {
      setNameVal(val);
    } else if (type === "email") {
      setEmailVal(val);
    } else if (type === "pswd") {
      setPswd(val);
    } else if (type === "confirmPswd") {
      setConfirmPswd(val);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: "50%" },
          bgcolor: "background.paper",
          border: 0,
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "GilroyBold",
              fontSize: 18,
            }}
          >
            {editData?.editType === "change_pswd"
              ? "Change Password"
              : editData?._id
              ? "Edit User"
              : "Add User"}
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
        </Box>

        {editData?.editType !== "change_pswd" ? (
          <Grid container item xs={12} spacing={1} mt={1}>
            <Grid item xs={6}>
              <SettingTextField
                page={"AddUserModal"}
                editable={true}
                value={nameVal}
                setValue={(val) => handlerOnChange("name", val)}
                label={"Name"}
                placeholder={""}
                required={true}
                variant={"filled"}
                border={"1px solid #AAA"}
                borderRadius={"5px"}
                error={error?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <SettingTextField
                page={"AddUserModal"}
                editable={true}
                value={emailVal}
                setValue={(val) => handlerOnChange("email", val)}
                label={"Email"}
                placeholder={""}
                required={true}
                variant={"filled"}
                border={"1px solid #AAA"}
                borderRadius={"5px"}
                error={error?.email}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid container item xs={12} spacing={1} mt={1}>
          <Grid item xs={editData?.editType === "change_pswd" ? 12 : 6}>
            <SettingTextField
              page={"AddUserModal"}
              type="password"
              editable={true}
              value={pswd}
              setValue={(val) => handlerOnChange("pswd", val)}
              label={"Password"}
              placeholder={""}
              required={true}
              variant={"filled"}
              border={"1px solid #AAA"}
              borderRadius={"5px"}
              error={error?.pswd}
            />
          </Grid>
          <Grid item xs={editData?.editType === "change_pswd" ? 12 : 6}>
            <SettingTextField
              page={"AddUserModal"}
              type="password"
              editable={true}
              value={confirmPswd}
              setValue={(val) => handlerOnChange("confirmPswd", val)}
              label={"Re Enter Password"}
              placeholder={""}
              required={true}
              variant={"filled"}
              border={"1px solid #AAA"}
              borderRadius={"5px"}
              error={error?.confirmPswd}
            />
          </Grid>
        </Grid>
        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            type="button"
            disabled={isLoading}
            sx={{
              backgroundColor: "#000",
              color: "#FFF",
              width: 100,
              opacity: isLoading ? 0.2 : 1,
              fontFamily: "GilroyBold",
              fontSize: 14,
              borderRadius: 1,
              "&:hover": {
                backgroundColor: "#000 !important",
              },
            }}
            onClick={isLoading ? null : onSubmitUser}
          >
            {editData?._id ? "Update" : "Save"}
          </Button>
        </Box>
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Box>
    </Modal>
  );
};

export default AddUserModal;
