import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import AddRestaurantCategoryModal from "../../components/AddRestaurantCategoryModal";

import "../../assets/css/custom.css";

import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: "GilroySemiBold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "GilroyMedium",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Categories = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "",
  });

  useEffect(() => {
    getRestaurantCategoryList();
  }, []);

  const getRestaurantCategoryList = async () => {
    const response = await Api.getRestaurantCategoryList("all");
    if (response?.success) {
      setCategoryList(response?.data?.rows);
    } else {
      setMsgAlert({ open: true, message: response?.msg, msgType: "error" });
    }
    setIsLoading(false);
  };

  const onAddEdit = (item) => {
    setEditData(item);
    setAddModalVisible(true);
  };

  const onCloseModal = () => {
    setAddModalVisible(false);
    setEditData(null);
  };

  const onSubmit = (data) => {
    let list = [...categoryList];
    const findIndex = list.findIndex((x) => x?._id === data?._id);
    if (findIndex >= 0) {
      list[findIndex] = { ...list[findIndex], ...data };
    } else {
      list.push(data);
    }
    setCategoryList(list);
    setAddModalVisible(false);
    setEditData(null);
  };

  return (
    <Container
      page={"categories"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant={"h6"}
              sx={{
                fontFamily: "GilroyBold",
              }}
            >
              Restaurant Categories
            </Typography>

            <Button
              type="button"
              sx={{
                backgroundColor: "#000",
                color: "#FFF",
                width: 100,
                fontFamily: "GilroyBold",
                fontSize: 14,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#000 !important",
                },
              }}
              onClick={() => onAddEdit(null)}
            >
              + ADD
            </Button>
          </Box>

          <Grid container direction="row" mt={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryList.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell sx={{ width: 40 }}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <EditIcon
                          height={20}
                          width={20}
                          style={{ cursor: "pointer" }}
                          onClick={() => onAddEdit(row)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
      <AddRestaurantCategoryModal
        open={addModalVisible}
        editData={editData}
        onClose={onCloseModal}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default Categories;
